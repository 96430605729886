import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography, ThemeProvider, createTheme, Divider, Link } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import PageHeader from './Component/PageHeader';
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from 'react-google-recaptcha';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#EA2B1F", // custom secondary color
        },
    },
});

const Reservations = () => {

    const [recaptchaValue, setRecaptchaValue] = React.useState('');

    const formik = useFormik({
        initialValues: {
            fullname: '',
            phonenumber: '',
            email: '',
            PickupAddress: '',
            DropAddress: '',
            honeypot: ''
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required('Full Name is required'),
            phonenumber: Yup.string().required('Phone Number is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            PickupAddress: Yup.string().required('Pickup Address is required'),
            DropAddress: Yup.string().required('Drop-off is required'),
            honeypot: Yup.string(),
        }),




        onSubmit: (values, { resetForm }) => {

            if (!recaptchaValue) {
                alert('Please complete the reCAPTCHA!');
                return; // Stop form submission if reCAPTCHA is not verified
            }

            if (values.honeypot) {
                console.log('Honeypot detected');
                return; // Stop form submission if honeypot is filled
            }

            emailjs.sendForm('service_p2vo5p9', 'template_yuyfjnc', '#contactForm', 'ffXZhTQHcHeAJRGOl')
                .then((result) => {
                    console.log(result.text);
                    alert('Thank you for your reservation! We have received your booking details and will contact you shortly to confirm. We appreciate your trust in Boston Airport Limousine.');
                    resetForm();
                }, (error) => {
                    console.log(error.text);
                    alert('Failed to send the message, please try again.');
                });
        },
    });

    const handleRecaptcha = (value) => {
        setRecaptchaValue(value);
    };

    return (
        <div>
            <Helmet>
                <title>Book a Limo | Luxury Limo Service in Boston</title>

                <meta data-react-helmet="true" name="description" content="Book a limo with our luxury limo service. Offering black car services, Boston airport limousine service, and private limo service for Logan Airport." />
                <meta data-react-helmet="true" name="keywords" content="book a limo, limo hire, luxury limo service, black car services, boston airport limousine service, boston limo service logan airport, private limo service" />
                <meta data-react-helmet="true" property="og:title" content="Book a Limo | Luxury Limo Service in Boston" />
                <meta data-react-helmet="true" property="og:type" content="business.local" />
                <meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Reservations" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
                <meta data-react-helmet="true" property="og:description" content="Experience premium black car services with our luxury limo service. Specializing in Boston airport limousine service and private limo service for Logan Airport. Book your ride now!" />

            </Helmet>

            <PageHeader pageTitle="Limousine Reservation" />

            <article>
                <section className='mx-16 my-4'>
                    <p>Book your luxurious limousine service in Boston today and experience the ultimate in comfort, style, and reliability. Whether you need a limo for corporate events, special occasions, or seamless transportation to and from the airport, our professional chauffeurs are here to ensure a stress-free ride. We specialize in airport limousine services, including timely pickups and drop-offs at Logan Airport and other major hubs. Check out our competitive <Link href="/AirportTransfers" title="">Airports rate</Link> for affordable and convenient travel solutions. Serving Boston and surrounding areas, we provide premium limousine services tailored to your schedule. Reserve your Boston limo now and enjoy a first-class travel experience every time.</p>
                </section>
            </article>

            <div className='mx-16 mt-4 mb-8'><Divider /></div>

            <div className='mx-16'>

                <div>
                    <ThemeProvider theme={theme}>
                        <div>
                            <form id="contactForm" onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <div className="relative">
                                            <Typography variant="subtitle1">
                                                Full Name
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="fullname"
                                                name="fullname"
                                                value={formik.values.fullname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                                                helperText={formik.touched.fullname && formik.errors.fullname}
                                                InputProps={{
                                                    startAdornment: <PersonIcon className="mr-2 " />,
                                                    sx: { backgroundColor: '#F4F6F6' },
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <div className="relative">
                                            <Typography variant="subtitle1" >
                                                Phone Number
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="phonenumber"
                                                name="phonenumber"
                                                value={formik.values.phonenumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
                                                helperText={formik.touched.phonenumber && formik.errors.phonenumber}
                                                InputProps={{
                                                    startAdornment: <PhoneIphoneIcon className="mr-2 text-inherit" />,
                                                    sx: { backgroundColor: '#F4F6F6' },
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <div className="relative">
                                            <Typography variant="subtitle1" >
                                                Email
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                                InputProps={{
                                                    startAdornment: <EmailIcon className="mr-2 text-inherit" />,
                                                    sx: { backgroundColor: '#F4F6F6' },
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="relative">
                                            <Typography variant="subtitle1" >
                                                Pickup Address
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="PickupAddress"
                                                name="PickupAddress"
                                                value={formik.values.PickupAddress}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.PickupAddress && Boolean(formik.errors.PickupAddress)}
                                                helperText={formik.touched.PickupAddress && formik.errors.PickupAddress}

                                                InputProps={{
                                                    startAdornment: <PlaceIcon className="mr-2 text-inherit" />,
                                                    sx: { backgroundColor: '#F4F6F6' },
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="relative">
                                            <Typography variant="subtitle1" >
                                                Drop-off Address
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="DropAddress"
                                                name="DropAddress"
                                                value={formik.values.DropAddress}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.DropAddress && Boolean(formik.errors.DropAddress)}
                                                helperText={formik.touched.DropAddress && formik.errors.DropAddress}

                                                InputProps={{
                                                    startAdornment: <PlaceIcon className="mr-2 text-inherit" />,
                                                    sx: { backgroundColor: '#F4F6F6' },
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField style={{ display: 'none' }}
                                            fullWidth

                                            id="honeypot"
                                            name="honeypot"
                                            value={formik.values.honeypot} // Bind it to a honeypot field in Formik

                                            hidden // Hides the element entirely

                                        />


                                        <div>
                                            {/* Google reCAPTCHA */}
                                            <ReCAPTCHA
                                                sitekey="6LfFz6cqAAAAAIb7jbgYdPeIz1uUI1bSlPuZhmlF"
                                                onChange={handleRecaptcha}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                            style={{ color: "inherit" }}
                                            startIcon={<SendIcon />}
                                        >
                                            Send
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default Reservations;