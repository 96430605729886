import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PageHeader from './Component/PageHeader';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';
import { useURLSecurity } from './utils/urlSecurityUtils';

const theme = createTheme({
    palette: {
        primary: { main: "#99aab5" },
        secondary: { main: "#C13E39" },
    },
});

const NotFoundPage = () => {
    const location = useLocation();
    const securityReport = useURLSecurity(location.pathname);

    React.useEffect(() => {
        if (securityReport?.severity === "HIGH") {
            // You might want to take additional actions for high-severity threats
            console.warn("High severity security threat detected:", securityReport);
        }
    }, [securityReport]);

    return (
        <div>
            <PageHeader pageTitle="404 - Page Not Found" />
            <Helmet prioritizeSeoTags>
                <title>404 - Page Not Found</title>
                <meta
                    data-react-helmet="true"
                    name="description"
                    content="Oops! The page you're looking for doesn't exist. Check the URL or return to the homepage for more information."
                />

                <meta
                    data-react-helmet="true"
                    property="og:title"
                    content="404 - Page Not Found"
                />

                <meta
                    data-react-helmet="true"
                    property="og:url"
                    content="https://bostonairportlimos.com/NotFoundPage"
                />

            </Helmet>
            <section className="mx-16">
                <article className="lg:flex lg:gap-5">
                    <section className="lg:w-3/12">
                        <h2
                            className="text-9xl font-bold text-center"
                            style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)" }}
                            aria-label="Error code: 404"
                        >
                            404
                        </h2>
                    </section>

                    <section className="lg:w-9/12">
                        <div className="p-6 max-w-lg mx-auto mt-8">
                            <h1 className="text-2xl font-bold mb-4">
                                {securityReport?.severity === "HIGH"
                                    ? "Access Denied"
                                    : "Page Not Found"}
                            </h1>
                            <p className="text-gray-600 mb-4">
                                {securityReport?.severity === "HIGH"
                                    ? "This request has been blocked for security reasons."
                                    : "The page you're looking for doesn't exist. Please check the URL and try again."}
                            </p>
                        </div>

                        <div className="lg:w-6/12 mx-auto">
                            <ThemeProvider theme={theme}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    aria-label="Go back to home page"
                                    title="Go back to the home page"
                                    href="/"
                                >
                                    <HomeIcon />&nbsp;&nbsp;<b>Go Back to Home</b>
                                </Button>
                            </ThemeProvider>
                        </div>
                    </section>
                </article>
                <div className="my-8"><Divider /></div>
            </section>

            <section className="mx-16">
                <p>
                    You can use the links below to navigate to other parts of our website or explore the full list of pages in our sitemap:
                </p>
                <p>
                    Our sitemap includes a complete list of all the pages on our website, making it easier for you to find exactly what you need. If you have any trouble, feel free to contact us.
                </p>
                <p>
                    Thank you for visiting!
                </p>
            </section>


        </div>
    );
};

export default NotFoundPage;
