import React from 'react'
import PageHeader from '../Component/PageHeader';
import CompanyName from '../Component/CompanyName';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

const SportingEventsLimousine = () => {
	return (
		<div>
			<Helmet>
				<title>Sporting Events Limousine</title>
				<meta data-react-helmet="true" name="keywords" content="" />
				<meta data-react-helmet="true" name="description" content="" />
				<meta data-react-helmet="true" property="og:title" content="" />
				<meta data-react-helmet="true" property="og:type" content="business.local" />
				<meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Services/SportingEventsLimousine" />
				<meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
				<meta data-react-helmet="true" property="og:description" content="" />
			</Helmet>
			<PageHeader pageTitle="Sporting Events Limousine" />

			<div className='mx-16'>
				<section className='lg:float-right lg:ml-8 mb-8'>
					<figure>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/SportingEvents.webp?alt=media&token=3ead32e4-4f40-4b48-9f74-3754c2a8880a"
							alt="Airport Transfers"
							className='rounded-br-[45px] rounded-tl-[45px] w-full'
						/>
						<figcaption className="py-2 text-center">Luxury Transportation for Game Day</figcaption>
					</figure>
				</section>

				{/* Introduction Section */}
				<section>
					<p className='text-lg'>
						Skip the hassle of traffic and parking with our <CompanyName /> chauffeured limo service, ensuring smooth transportation to your favorite games or local events. Travel in comfort, cheer on your team, and enjoy a premium ride without the stress.
					</p>
				</section>

				{/* Main Content Section */}
				<article className="mt-8">
					{/* Why Choose Us Section */}
					<section className="service-description">
						<div className='text-2xl py-4 font-bold'><h2>Why Choose Our Sporting Event Service?</h2></div>
						<p className='text-lg leading-relaxed'>
							Our sporting event transportation service is designed to make your game day experience seamless and enjoyable. Here’s why you should choose us:
						</p>

						<ul className='py-4 list-disc list-inside'>
							<div className='mt-4 mb-8'><Divider /></div>
							<li className='py-2'>Stress-Free Travel: Avoid parking fees, traffic jams, and the hassle of navigating crowded venues.</li>
							<li className='py-2'>Group-Friendly: We offer vehicles that accommodate small or large groups, ensuring everyone travels together comfortably.</li>
							<li className='py-2'>Timely Arrivals: Our chauffeurs plan the best routes to get you to the game on time, relaxed, and ready to cheer.</li>
							<li className='py-2'>Luxury Amenities: Travel in style with our premium vehicles, equipped with modern amenities for your comfort.</li>
							<li className='py-2'>Tailgating Options: Enhance your game day experience with our tailgating packages, perfect for pre-game celebrations.</li>
						</ul>
					</section>

					{/* Ideal For Section */}
					<section className="service-ideal-for">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Ideal For</h2></div>
						<p className='text-lg leading-relaxed'>
							Our sporting event transportation service is perfect for a wide range of events and occasions. Here’s who can benefit the most:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'>Boston Red Sox, Celtics, Bruins, or Patriots games.</li>
							<li className='py-2'>Tailgating events or sports tournaments.</li>
							<li className='py-2'>Group outings to local sporting events or championships.</li>
							<li className='py-2'>Corporate events or team-building activities centered around sports.</li>
						</ul>
					</section>

					{/* Fleet Options Section */}
					<section className="fleet-options">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Our Fleet Options</h2></div>
						<p className='text-lg leading-relaxed'>
							We offer a variety of vehicles to suit your needs and preferences. Whether you're traveling solo or with a group, we have the perfect ride for you:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'>Luxury Sedans: Sleek and professional, ideal for small groups or couples.</li>
							<li className='py-2'>Luxury Limousines: Spacious and comfortable, perfect for mid-sized groups or families.</li>

						</ul>
					</section>

					{/* Booking Process Section */}
					<section className="booking-process">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>How to Book Your Sporting Event Transportation</h2></div>
						<p className='text-lg leading-relaxed'>
							Booking your transportation for sporting events is quick and easy. Follow these simple steps:
						</p>
						<ol className='py-4 list-decimal list-inside'>
							<li className='py-2'>Choose Your Service: Select the type of vehicle and any additional amenities you’d like.</li>
							<li className='py-2'>Provide Your Details: Enter your pickup location, destination, and event details.</li>
							<li className='py-2'>Confirm Your Booking: Review your details and confirm your reservation.</li>
							<li className='py-2'>Enjoy the Game: Sit back, relax, and let us handle the transportation while you focus on cheering for your team.</li>
						</ol>
					</section>

					{/* Call to Action Section */}
					<section className="service-booking mt-8">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Book Your Sporting Event Ride Today</h2></div>
						<p className='text-lg leading-relaxed'>
							Cheer in style and make your game day unforgettable with our luxury transportation service. Contact us to reserve your ride and ensure a seamless and stress-free experience. Whether it’s a local game or a championship event, we’re here to get you there in comfort and style.
						</p>
					</section>
				</article>
			</div>
		</div>
	)
}

export default SportingEventsLimousine