import React from 'react';
import CompanyName from './Component/CompanyName';
import Hero from './Component/Hero';
import { Helmet } from 'react-helmet-async';
import Services from './Services';


const Home = () => {
    return (
        <div>
            <Helmet>
                <title data-react-helmet="true">Boston Limousine Service - Luxury Airport and Event Transportation</title>
                <meta data-react-helmet="true" name="description" content="Experience premium limousine service in Boston. Luxury airport transfers, corporate travel, and event transportation. Book your Boston limo now!" />
                <meta data-react-helmet="true" name="keywords" content="Boston limousine service, airport transportation, luxury car service, corporate limo Boston, special event limo Boston" />
                <meta data-react-helmet="true" property="og:title" content="Boston Limousine Service - Luxury Airport and Event Transportation" />
                <meta data-react-helmet="true" property="og:type" content="business.local" />
                <meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
                <meta data-react-helmet="true" property="og:description" content="Discover unmatched luxury with Boston's premier limousine service. From airport pickups to special events, we deliver excellence in every ride." />
            </Helmet>
            <div><Hero /></div>
            <div>
                <div className="mx-4 lg:mx-16 pt-8">

                    <section className="mb-12">
                        <h2 className="text-3xl font-bold mb-4">Welcome to <CompanyName /></h2>
                        <p className="mb-4">
                            At <CompanyName />, we redefine luxury transportation in Boston. Whether you need limousine services for a ride to Boston Logan Airport, corporate events, or special occasions, we have you covered. With a focus on elegance, professionalism, and timeliness, we are Boston's trusted choice for airport limo services, black car services, and luxury limousines. Let us ensure your journey is seamless, stylish, and unforgettable.
                        </p>
                    </section>


                    <section className="mb-12">
                        <Services />
                    </section>


                    <section className="mb-12">
                        <h2 className="text-3xl font-bold mb-4">Why Choose <CompanyName />?</h2>
                        <ul className="list-disc ml-8">
                            <li className="mb-2">
                                <strong>Experienced Chauffeurs:</strong> Our skilled and courteous drivers deliver exceptional service.
                            </li>
                            <li className="mb-2">
                                <strong>Competitive Pricing:</strong> Choose from a selection of high-end vehicles, including black car services and limousines.
                            </li>
                            <li className="mb-2">
                                <strong>Luxury Fleet:</strong> Enjoy premium limo hire without exceeding your budget.
                            </li>
                            <li className="mb-2">
                                <strong>Punctual and Reliable:</strong> Trust us to respect your time and schedule.
                            </li>
                        </ul>
                    </section>

                </div>
            </div>
        </div>
    )
}

export default Home