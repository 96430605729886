import React from 'react';

const PageHeader = ({ pageTitle }) => {
    return (
        <header className='mx-16'>
            <h1 className='lg:text-4xl text-xl font-bold mb-6 pt-12'>{pageTitle}</h1>

        </header>
    );
};


export default PageHeader