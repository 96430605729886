import { Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState, useEffect } from "react";
import PageHeader from "../Component/PageHeader";

const ITEMS_PER_PAGE = 50;

const NewJerseyLimousine = () => {
    const [ratesData, setRatesData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortKey, setSortKey] = useState("City"); // Default sorting by city
    const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
    const [currentPage, setCurrentPage] = useState(1);

    // Fetch data from JSON file
    useEffect(() => {
        fetch("/Rates.json")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch the data.");
                }
                return response.json();
            })
            .then((data) => setRatesData(data))
            .catch((error) => console.error("Error loading JSON:", error));
    }, []);

    // Filter and sort data
    const filteredData = ratesData
        .filter(
            (item) =>
                item.Active === "YES" && // Include only active items
                item.State.toUpperCase() === "NJ" && // Match the state explicitly
                item.City.toLowerCase().includes(searchTerm.toLowerCase()) // Match city search term
        )
        .sort((a, b) => {
            const compareA = sortOrder === "asc" ? a[sortKey] : b[sortKey];
            const compareB = sortOrder === "asc" ? b[sortKey] : a[sortKey];
            return typeof compareA === "string"
                ? compareA.localeCompare(compareB)
                : compareA - compareB;
        });

    // Pagination logic
    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    // Handlers
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on new search
    };

    const handleSort = (key) => {
        if (sortKey === key) {
            // Toggle sort order
            setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortKey(key);
            setSortOrder("asc"); // Default to ascending when changing sort key
        }
    };

    const handlePageChange = (page) => setCurrentPage(page);

    return (
        <div className="mx-16">
            <div><PageHeader pageTitle="Limousine Rates in New Jersey" /></div>


            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search by city or state"
                className="w-full p-2 border rounded mb-4"
                onChange={handleSearch}
            />

            {/* Table */}
            <TableContainer>
                <Table className="overflow-x-auto table-auto">
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => handleSort("City")} sx={{ color: "inherit" }}>
                                <div className="font-bold text-lg">Cities / Towns {sortKey === "City" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                            </TableCell>
                            <TableCell onClick={() => handleSort("State")} sx={{ color: "inherit" }}>
                                <div className="font-bold text-lg">State {sortKey === "State" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                            </TableCell>
                            <TableCell onClick={() => handleSort("Sedan")} sx={{ color: "inherit" }}>
                                <div className="font-bold text-lg">Sedan Rate {sortKey === "Sedan" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                            </TableCell>
                            <TableCell onClick={() => handleSort("SUV")} sx={{ color: "inherit" }}>
                                <div className="font-bold text-lg">SUV Rate {sortKey === "SUV" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {paginatedData.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ color: "inherit" }}>{item.City || "N/A"}</TableCell>
                            <TableCell sx={{ color: "inherit" }}>{item.State || "N/A"}</TableCell>
                            <TableCell sx={{ color: "inherit" }}>${Number(item.Sedan) || "-"}.00</TableCell>
                            <TableCell sx={{ color: "inherit" }}>${Number(item.SUV) || "-"}.00</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={4} sx={{ color: "inherit" }}>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i}
                                    className={`mx-1 px-3 py-1 rounded-lg border border-gray-300 ${currentPage === i + 1
                                        ? "bg-gray-100 text-black font-semibold"
                                        : "bg-white hover:bg-gray-100"
                                        }`}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>


            {/* Pagination */}
            <div className="flex justify-center items-center mt-4">
                <div className="inline-flex border-t border-gray-300 py-2">

                </div>
            </div>
        </div>
    );
};

export default NewJerseyLimousine