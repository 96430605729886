import React from "react";
// src/utils/urlSecurityUtils.js

// Common attack patterns
const ATTACK_PATTERNS = {
    // File extensions that shouldn't be requested from a React app
    SUSPICIOUS_EXTENSIONS: /\.(php|aspx?|jsp|cgi|cfm|pl|py|rb|sh|exe|dll|db|sql|ini|conf|log|env)$/i,

    // Common attack path patterns
    SUSPICIOUS_PATHS: /\b(admin|wp-|shell|install|backup|config|setup|phpmy|sql|db|auth)\b/i,

    // SQL injection attempts
    SQL_INJECTION: /['";]|(--[^\n]*$)|(\/\*.*\*\/)|(union.*select)|(\bor\b.*\btrue\b)/i,

    // Path traversal attempts
    PATH_TRAVERSAL: /(\.\.\/|\.\.\\|~\/|\.\.|\/\.\.)/i,

    // Command injection attempts
    COMMAND_INJECTION: /[;&|`]|\$\(|\b(cat|echo|rm|chmod|mkdir|tar|wget|curl)\b/i,

    // XSS attempts
    XSS_PATTERNS: /<[^>]*>|javascript:|data:|vbscript:|on\w+\s*=|alert\(|confirm\(|prompt\(/i,

    // File inclusion attempts
    FILE_INCLUSION: /(include|require|load|import|file)[=\s].+/i,

    // Protocol exploitation attempts
    PROTOCOL_EXPLOITATION: /^(file|data|ftp|ldap|dict|gopher|jar|rsync):/i
};

// Normalized character sets that might be used to bypass filters
const NORMALIZED_CHARS = {
    'a': /[aA\u00e0-\u00e5\u0101]/g,
    'e': /[eE\u00e8-\u00eb]/g,
    'i': /[iI\u00ec-\u00ef]/g,
    'o': /[oO\u00f2-\u00f6]/g,
    'u': /[uU\u00f9-\u00fc]/g,
    's': /[sS\u00df]/g
};

class URLSecurityChecker {
    constructor() {
        this.detectedThreats = new Set();
        this.decodedURL = '';
    }

    // Normalize and decode the URL
    normalizeURL(url) {
        let decoded = decodeURIComponent(url);

        // Convert normalized characters
        Object.entries(NORMALIZED_CHARS).forEach(([char, pattern]) => {
            decoded = decoded.replace(pattern, char);
        });

        // Remove multiple slashes and normalize separators
        decoded = decoded.replace(/\/+/g, '/').toLowerCase();

        this.decodedURL = decoded;
        return decoded;
    }

    // Check for specific threat patterns
    checkPatterns(url) {
        const normalizedURL = this.normalizeURL(url);

        Object.entries(ATTACK_PATTERNS).forEach(([threatType, pattern]) => {
            if (pattern.test(normalizedURL)) {
                this.detectedThreats.add(threatType);
            }
        });

        return this.detectedThreats.size > 0;
    }

    // Analyze URL structure for anomalies
    analyzeURLStructure(url) {
        const urlObj = new URL(url, window.location.origin);

        // Check for suspicious query parameters
        const params = new URLSearchParams(urlObj.search);
        for (const [key, value] of params) {
            // Check for potentially dangerous parameter names
            if (ATTACK_PATTERNS.SUSPICIOUS_PATHS.test(key)) {
                this.detectedThreats.add('SUSPICIOUS_PARAMETER_NAME');
            }

            // Check for potentially dangerous parameter values
            if (ATTACK_PATTERNS.XSS_PATTERNS.test(value) ||
                ATTACK_PATTERNS.SQL_INJECTION.test(value)) {
                this.detectedThreats.add('SUSPICIOUS_PARAMETER_VALUE');
            }
        }

        // Check path depth (unusually deep paths might be suspicious)
        const pathDepth = urlObj.pathname.split('/').filter(Boolean).length;
        if (pathDepth > 5) {
            this.detectedThreats.add('EXCESSIVE_PATH_DEPTH');
        }

        return this.detectedThreats.size > 0;
    }

    // Generate security report
    generateReport() {
        return {
            originalURL: this.decodedURL,
            threats: Array.from(this.detectedThreats),
            timestamp: new Date().toISOString(),
            userAgent: window.navigator.userAgent,
            severity: this.calculateSeverity()
        };
    }

    // Calculate threat severity
    calculateSeverity() {
        const threatWeights = {
            'SQL_INJECTION': 5,
            'XSS_PATTERNS': 4,
            'PATH_TRAVERSAL': 4,
            'COMMAND_INJECTION': 5,
            'SUSPICIOUS_EXTENSIONS': 2,
            'SUSPICIOUS_PATHS': 1,
            'SUSPICIOUS_PARAMETER_NAME': 2,
            'SUSPICIOUS_PARAMETER_VALUE': 3,
            'EXCESSIVE_PATH_DEPTH': 1
        };

        let totalWeight = 0;
        this.detectedThreats.forEach(threat => {
            totalWeight += threatWeights[threat] || 1;
        });

        if (totalWeight >= 5) return 'HIGH';
        if (totalWeight >= 3) return 'MEDIUM';
        return 'LOW';
    }
}

// Usage in your React components
export const useURLSecurity = (path) => {
    const [securityReport, setSecurityReport] = React.useState(null);

    React.useEffect(() => {
        const checker = new URLSecurityChecker();

        try {
            // Run all security checks
            const hasPatternThreats = checker.checkPatterns(path);
            const hasStructuralThreats = checker.analyzeURLStructure(path);

            if (hasPatternThreats || hasStructuralThreats) {
                const report = checker.generateReport();
                setSecurityReport(report);

                // Log security event
                logSecurityEvent(report);
            }
        } catch (error) {
            console.error('Security check failed:', error);
        }
    }, [path]);

    return securityReport;
};

// Logging function
const logSecurityEvent = (report) => {
    if (process.env.NODE_ENV === 'development') {
        console.warn('Security Event Detected:', report);
    }

    // In production, you might want to send to your logging service
    if (process.env.NODE_ENV === 'production') {
        // Example: Send to logging service
        // await fetch('/api/security-log', {
        //   method: 'POST',
        //   body: JSON.stringify(report)
        // });
    }
};