import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography, ThemeProvider, createTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import CommentIcon from '@mui/icons-material/Comment';
import CompanyName from './Component/CompanyName';
import PageHeader from './Component/PageHeader';
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from 'react-google-recaptcha';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#EA2B1F", // custom secondary color
        },
    },
});

const ContactUs = () => {

    const [recaptchaValue, setRecaptchaValue] = React.useState('');

    const formik = useFormik({
        initialValues: {
            fullname: '',
            phonenumber: '',
            email: '',
            message: '',
            honeypot: ''
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required('Full Name is required'),
            phonenumber: Yup.string().required('Phone Number is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            message: Yup.string().required('Message is required'),
            honeypot: Yup.string(),
        }),




        onSubmit: (values, { resetForm }) => {

            if (!recaptchaValue) {
                alert('Please complete the reCAPTCHA!');
                return; // Stop form submission if reCAPTCHA is not verified
            }

            if (values.honeypot) {
                console.log('Honeypot detected');
                return; // Stop form submission if honeypot is filled
            }

            emailjs.sendForm('service_p2vo5p9', 'template_ntgajwn', '#contactForm', 'ffXZhTQHcHeAJRGOl')
                .then((result) => {
                    console.log(result.text);
                    alert('Thank you for contacting us! We have received your request and will be in touch with you as soon as possible, We appreciate your time ');
                    resetForm();
                }, (error) => {
                    console.log(error.text);
                    alert('Failed to send the message, please try again.');
                });
        },
    });

    const handleRecaptcha = (value) => {
        setRecaptchaValue(value);
    };

    return (
        <div className="">

            <Helmet>
                <title>Contact Boston Airport Limousine Service | Luxurious Rides</title>

                <meta data-react-helmet="true" name="description" content="Contact Boston Airport Limo Service for luxurious, reliable rides to and from Logan Airport. Book your airport limo in Boston, MA today!" />
                <meta data-react-helmet="true" name="keywords" content="airport limo service boston ma, airport limousine, boston airport limo, boston airport limousine service, limo service to boston logan, limo to boston airport, black car service, limousine service boston logan airport" />
                <meta data-react-helmet="true" property="og:title" content="Contact Boston Airport Limo Service | Luxurious Rides" />
                <meta data-react-helmet="true" property="og:type" content="business.local" />
                <meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/ContactUs" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
                <meta data-react-helmet="true" property="og:description" content="Reach out to Boston Airport Limousine Service for premium airport transportation in Boston, MA. Enjoy reliable, luxurious, and punctual airport limo services to and from Logan Airport." />

            </Helmet>
            <PageHeader pageTitle={<><span>Contact </span><CompanyName /></>} />
            <article>
                <section className='mx-16'>
                    <p>
                        Looking for a luxurious and reliable airport limo service in Boston, MA? Whether you need a Boston Logan Airport limousine for business travel or a comfortable ride to catch your flight, we've got you covered. Our airport black car service ensures punctuality, professionalism, and style, making your journey to or from Logan Airport seamless. Contact us today to book your Boston airport limo and experience the convenience of premium transportation tailored to your schedule. Let us take the stress out of your travel plans!
                    </p>
                    <p className='py-4'>
                        <CompanyName /> Owned and Operated By BLC.
                    </p>
                </section>
            </article>

            <div className="mx-16">


                <div className='py-8'>
                    <div>
                        <div>
                            <ThemeProvider theme={theme}>
                                <div>
                                    <form id="contactForm" onSubmit={formik.handleSubmit}>
                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <div className="relative">
                                                    <Typography variant="subtitle1">
                                                        Full Name
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="fullname"
                                                        name="fullname"
                                                        value={formik.values.fullname}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                                                        helperText={formik.touched.fullname && formik.errors.fullname}
                                                        InputProps={{
                                                            startAdornment: <PersonIcon className="mr-2 " />,
                                                            sx: { backgroundColor: '#F4F6F6' },
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="relative">
                                                    <Typography variant="subtitle1" >
                                                        Phone Number
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="phonenumber"
                                                        name="phonenumber"
                                                        value={formik.values.phonenumber}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
                                                        helperText={formik.touched.phonenumber && formik.errors.phonenumber}
                                                        InputProps={{
                                                            startAdornment: <PhoneIphoneIcon className="mr-2 text-inherit" />,
                                                            sx: { backgroundColor: '#F4F6F6' },
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="relative">
                                                    <Typography variant="subtitle1" >
                                                        Email
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="email"
                                                        name="email"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                                        helperText={formik.touched.email && formik.errors.email}
                                                        InputProps={{
                                                            startAdornment: <EmailIcon className="mr-2 text-inherit" />,
                                                            sx: { backgroundColor: '#F4F6F6' },
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="relative">
                                                    <Typography variant="subtitle1" >
                                                        Message
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="message"
                                                        name="message"
                                                        value={formik.values.message}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.message && Boolean(formik.errors.message)}
                                                        helperText={formik.touched.message && formik.errors.message}
                                                        multiline
                                                        rows={4}
                                                        InputProps={{
                                                            startAdornment: <CommentIcon className="mr-2 text-inherit" />,
                                                            sx: { backgroundColor: '#F4F6F6' },
                                                        }}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField style={{ display: 'none' }}
                                                    fullWidth

                                                    id="honeypot"
                                                    name="honeypot"
                                                    value={formik.values.honeypot} // Bind it to a honeypot field in Formik

                                                    hidden // Hides the element entirely

                                                />


                                                <div>
                                                    {/* Google reCAPTCHA */}
                                                    <ReCAPTCHA
                                                        sitekey="6LfFz6cqAAAAAIb7jbgYdPeIz1uUI1bSlPuZhmlF"
                                                        onChange={handleRecaptcha}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    type="submit"
                                                    fullWidth
                                                    style={{ color: "inherit" }}
                                                    startIcon={<SendIcon />}
                                                >
                                                    Send
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </ThemeProvider>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default ContactUs