import React from 'react';
import CompanyName from './Component/CompanyName';
import { Button } from '@mui/material';
import JoinLogan from './Images/JoinBostonLoganCoach.jpg';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import ContactMailIcon from '@mui/icons-material/ContactMail';


const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#EA2B1F", // custom secondary color
        },
    },
});

const CName = () => "Boston Logan Coach";

const Careers = () => {

    const today = new Date();
    const formattedMonthYear = new Intl.DateTimeFormat("en-US", {
        month: "long",
        year: "numeric",
    }).format(today);

    return (
        <div>


            {/* Introduction Section */}
            <div className="container mx-auto px-6 ">
                <h1 className="text-4xl font-bold mb-6 pt-12">Join Our Team</h1>
                <p className="text-lg leading-relaxed mb-8">
                    At <CompanyName />, we’re proud to offer an exceptional working environment where professionalism and passion meet. As of {formattedMonthYear}, we are actively seeking dedicated drivers to join our growing team.
                    Whether you’re looking to advance your career or find a flexible role with excellent earning potential, <CompanyName /> is the place for you.
                </p>
                <img
                    src={JoinLogan}
                    alt={`Join our team at ${CName()}`}
                    title={`Join our team at ${CName()}`}
                    className="rounded-lg mb-8"
                />
            </div>

            {/* Why Work Here Section */}
            <div className="container mx-auto px-6 ">
                <h2 className="text-4xl font-bold mb-6 pt-8">Why Choose <CompanyName />?</h2>
                <ul className="list-disc pl-6 text-lg mb-6 space-y-3">
                    <li>Competitive pay with additional tips for excellent service.</li>
                    <li>Flexible schedules to fit your personal and family needs.</li>
                    <li>Opportunities for career growth within the company.</li>
                    <li>Supportive and collaborative work environment.</li>
                    <li>Be a part of a trusted and respected transportation provider.</li>
                </ul>
                <img
                    src={JoinLogan}
                    alt={`Join our team at ${CName()}`}
                    title={`Join our team at ${CName()}`}
                    className="rounded-lg mb-8"
                />
            </div>

            {/* Job Requirements */}
            <div className="container mx-auto px-6 ">
                <h2 className="text-4xl font-bold mb-6 pt-8">What We’re Looking For</h2>
                <p className="text-lg leading-relaxed mb-8">
                    To maintain the highest standards of service for our clients, we require the following qualifications from all applicants:
                </p>
                <ul className="list-disc pl-6 text-lg mb-6 space-y-3">
                    <li>A valid driver’s license and necessary permits.</li>
                    <li>A clean driving record with no major traffic violations.</li>
                    <li>At least 2 years of professional driving experience.</li>
                    <li>Familiarity with Boston and surrounding areas.</li>
                    <li>Strong communication and interpersonal skills.</li>
                </ul>
                <img
                    src={JoinLogan}
                    alt={`Join our team at ${CName()}`}
                    title={`Join our team at ${CName()}`}
                    className="rounded-lg mb-8"
                />
            </div>

            {/* Testimonials */}
            <div className="container mx-auto px-6 ">
                <h2 className="text-4xl font-bold mb-6 pt-8">Hear From Our Team</h2>
                <p className="text-lg italic mb-4">
                    "Working at <CompanyName /> has been life-changing. The flexibility and respect I receive as a driver make this job fulfilling every day." – Alex M.
                </p>
                <p className="text-lg italic mb-4">
                    "The supportive work environment and opportunities for growth are unmatched. I love being part of such a professional team." – Jamie L.
                </p>
                <img
                    src={JoinLogan}
                    alt={`Join our team at ${CName()}`}
                    title={`Join our team at ${CName()}`}
                    className="rounded-lg mb-8"
                />
            </div>

            {/* Application Process */}
            <div className="container mx-auto px-6 ">
                <h2 className="text-4xl font-bold mb-6 pt-8">How to Apply</h2>
                <p className="text-lg leading-relaxed mb-8">
                    Applying is simple. Click the button below to get in touch with our hiring team, and we’ll guide you through the process. Make sure to have your updated resume and driving credentials ready.
                </p>
                <div className="w-1/2 mx-auto">
                    <ThemeProvider theme={theme}>
                        <Button
                            fullWidth
                            variant="contained"
                            id="btnCallNow"
                            color="secondary"
                            style={{ color: "inherit" }}
                            size="large"
                            title="Call Boston Logan Coach Now"
                            startIcon={<ContactMailIcon />}
                        >
                            <b>Contact <CompanyName /></b>
                        </Button>
                    </ThemeProvider>
                </div>
            </div>

            {/* Call to Action */}
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-4xl font-bold mb-6 pt-8">Take the Next Step</h2>
                <p className="text-lg leading-relaxed mb-6">
                    Don’t wait—apply today and start your journey with <CompanyName />. We look forward to welcoming you to our team.
                </p>
            </div>
        </div>
    );
};

export default Careers