import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
} from "@mui/material";
import airports from "./Component/Airports.json";
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PageHeader from "./Component/PageHeader";
import { Helmet } from 'react-helmet-async';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#EA2B1F", // custom secondary color
        },
    },
});

const AirportTransfers = () => {



    return (
        <section>



            <Helmet>
                <title>Airport Transfer Service | Airport-to-Airport Transfers</title>
                <meta data-react-helmet="true" name="keywords" content="Airport Transfer, Airport-to-Airport Transfers, Limousine Service, Luxury Airport Transfers, Seamless Airport Transfers, Reliable Airport Transportation, Professional Airport Service" />
                <meta data-react-helmet="true" name="description" content="Enjoy seamless airport-to-airport transfers with our luxury limousine service. professional airport transfers at competitive rates." />
                <meta data-react-helmet="true" property="og:title" content="Airport Transfer Service | Reliable Airport Transfers" />
                <meta data-react-helmet="true" property="og:type" content="business.local" />
                <meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/AirportTransfers" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
                <meta data-react-helmet="true" property="og:description" content="Experience stress-free airport-to-airport transfers with our luxury limousine service. Reliable, punctual, and professional transportation for seamless connections." />
            </Helmet>
            <PageHeader pageTitle="Airport Transfers" />
            <div className="mx-16">
                <article className="pb-8">
                    Our Airport Transfer service offers seamless and reliable transportation specifically for transfers from one airport to another. Please note that the pricing displayed is exclusively for Airport-to-Airport Transfers, ensuring you receive the most competitive rates for this specific travel need. For luxury travel in style, our fleet of limousines guarantees a smooth ride while maintaining punctuality and convenience. Choose our professional airport transfer service for a stress-free connection between airports.
                </article>

                <TableContainer component="section">
                    <Table className="overflow-x-auto">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "inherit" }}></TableCell>
                                <TableCell sx={{ color: "inherit" }}><div className="text-center font-bold text-lg">Airport Limousine</div></TableCell>
                                <TableCell sx={{ color: "inherit" }}><div className="text-center font-bold text-lg">Airport Code</div></TableCell>
                                <TableCell sx={{ color: "inherit" }}><div className="text-center font-bold text-lg">State</div></TableCell>
                                <TableCell sx={{ color: "inherit" }}><div className="text-center font-bold text-lg">Sedan</div></TableCell>
                                <TableCell sx={{ color: "inherit" }}><div className="text-center font-bold text-lg">SUV</div></TableCell>
                                <TableCell sx={{ color: "inherit" }}><div className="text-center font-bold text-lg">Reservation</div></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {airports.map((airport, index) => (
                                <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell sx={{ color: "inherit" }}>
                                            <figure>
                                                <img
                                                    src={airport.logo}
                                                    alt={`Reserve limousine service to or from ${airport.name}`}
                                                    className="mx-auto lg:w-[100px]"
                                                    title={`Reserve limousine service to or from ${airport.name}`}
                                                />
                                                <figcaption className="sr-only">{`${airport.name} logo`}</figcaption>
                                            </figure>
                                        </TableCell>
                                        <TableCell sx={{ color: "inherit" }}><div className="text-center">{airport.name}</div></TableCell>
                                        <TableCell sx={{ color: "inherit" }}><div className="text-center">{airport.code}</div></TableCell>
                                        <TableCell sx={{ color: "inherit" }}><div className="text-center">{airport.state}</div></TableCell>
                                        <TableCell sx={{ color: "inherit" }}><div className="text-center">${airport.sedan}</div></TableCell>
                                        <TableCell sx={{ color: "inherit" }}><div className="text-center">${airport.suv}</div></TableCell>
                                        <TableCell sx={{ color: "inherit" }}>
                                            <div className="text-center print:hidden">
                                                <ThemeProvider theme={theme}>
                                                    <Button
                                                        variant="contained"
                                                        id="btnReserveNow"
                                                        color="secondary"
                                                        style={{ color: "inherit" }}
                                                        size="small"
                                                        title={`Reserve limousine service to or from ${airport.name}`}
                                                        startIcon={<EventAvailableIcon />}
                                                        href="/Reservations"
                                                    >
                                                        <b>Reserve</b>
                                                    </Button>
                                                </ThemeProvider>
                                            </div>
                                        </TableCell>
                                    </TableRow>

                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </section>
    )
}

export default AirportTransfers