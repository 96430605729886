import React from 'react';
import ServicesData from './Component/Services.json'
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import Button from '@mui/material/Button';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import ForwardIcon from '@mui/icons-material/Forward';


const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#EA2B1F", // custom secondary color
        },
    },
});

const Services = () => {
    return (
        <div>

            <div className="text-3xl font-bold mb-4"><h2>Our Premium Services</h2></div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
                {ServicesData.map((Service, index) => (
                    <div key={index} className='my-4'>
                        <Card sx={{
                            boxShadow: 3,
                            borderRadius: 2,
                            padding: 0,
                            backgroundColor: 'transparent',
                            color: 'inherit',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                        }}>
                            <CardHeader

                                title={Service.ServiceName}
                                style={{ fontWeight: 'bold', color: 'inherit' }}
                            // subheader="September 14, 2016"
                            />

                            <CardMedia
                                component="img"
                                image={Service.ServiceImage}
                                alt={`${Service.ServiceName} Limousine`}
                                title={`${Service.ServiceName} Limousine`}
                            />
                            <CardContent>
                                <div>{Service.ServiceDescription}</div>
                            </CardContent>
                            <div className='lg:w-3/4 mt-4 mb-8 mx-auto'>
                                <div className='mx-8'>
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            id="btnReserveNow"
                                            color="secondary"
                                            href={Service.ServiceLink}
                                            style={{ color: "inherit" }}
                                            size="large"
                                            title={`Reserve ${Service.ServiceName} Limousine`}
                                            endIcon={<ForwardIcon />}
                                        >
                                            <b>{Service.ServiceName}</b>
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>

                        </Card>


                    </div>
                ))
                }

            </div >
        </div >
    )
}

export default Services