import React from 'react'
import CompanyName from './CompanyName'
import { Link } from '@mui/material'
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

const navItems = [
	{ name: "Home", path: "/" },
	{ name: "Services", path: "/LuxuryLimousineServices" },
	{ name: "Limousine Rates", path: "/LimousineRates" },
	{ name: "Airport Transfers", path: "/AirportTransfers" },
	{ name: "Reservation", path: "/Reservations" },
	{ name: "Contact Us", path: "/ContactUs" },
];

const Navigation = ({ isDarkMode, toggleDarkMode, isOpen, setIsOpen }) => {
	return (
		<nav role="navigation" aria-label="Main Navigation">
			<section className="flex justify-between items-center p-4 print:hidden">
				{/* Company Name (Left Side) */}
				<article className="flex-shrink-0 font-bold lg:text-2xl">
					<h2><Link href='/' style={{ color: 'inherit', textDecoration: 'none' }}><CompanyName /></Link></h2>
				</article>

				{/* Navigation Links and Dark Mode Button (Right Side) */}
				<article className="flex items-center space-x-4 ">
					{/* Navigation Links */}
					<div className="hidden md:flex space-x-4 text-xl">
						{navItems.map((item) => (
							<Link
								key={item.name}
								href={item.path}
								title={`${item.name} ${item.titleName}`}
								className={`${isDarkMode ? 'text-[#c7d5e0]' : 'text-[#171a21]'}`}
								aria-label={`Go to ${item.name}`}
								style={{ color: "inherit", textDecoration: "none", position: "relative", overflow: "hidden", cursor: "pointer", fontWeight: 'bold' }}
							>
								{item.name}
							</Link>
						))}


					</div>

					{/* Dark Mode Toggle */}
					<IconButton
						onClick={toggleDarkMode}
						color="inherit"
						title={isDarkMode ? "Light Mode" : "Dark Mode"}
						aria-label={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
					>
						{isDarkMode ?
							<LightModeIcon style={{ color: '#D14009' }} /> :
							<DarkModeIcon style={{ color: '#D14009' }} />
						}
					</IconButton>

					{/* Mobile Menu Toggle */}
					<div className="md:hidden">
						<IconButton
							onClick={() => setIsOpen(prev => !prev)}
							style={{ color: isDarkMode ? '#F6F1D5' : '#171a21' }}
							aria-label="Toggle Menu"
						>
							<MenuIcon />
						</IconButton>
					</div>
				</article>
			</section>

			{/* Mobile Menu */}
			{isOpen && (
				<section className="md:hidden mt-4 pl-8">
					{navItems.map((item) => (
						<Link
							key={item.name}
							href={item.path}
							title={`${item.name} ${item.titleName}`}
							className={`block ${isDarkMode ? 'text-[#c7d5e0]' : 'text-[#171a21]'}py-2`}
							aria-label={`Go to ${item.name}`}
							style={{ color: "inherit", textDecoration: "none", position: "relative", overflow: "hidden", cursor: "pointer", fontWeight: 'bold' }}
						>
							{item.name}
						</Link>
					))}


				</section>


			)}



		</nav>
	)
}

export default Navigation