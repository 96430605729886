import React from 'react'
import FleetData from './Component/Fleet.json'
import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName'
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

const Fleet = () => {
    return (

        <div>
            <Helmet>
                <title>Premium Limousine Services, Boston Airport Limousine Fleet</title>
                <meta data-react-helmet="true" name="keywords" content="limousine services, airport limousine service, black car services, Boston airport limousine service, limo company" />
                <meta data-react-helmet="true" name="description" content="Our fleet at Boston Airport Limousine, offering luxury, safety, and comfort. premium limousine services, airport transfers, and black car services." />
                <meta data-react-helmet="true" property="og:title" content="Limousine Services, Fleet at Boston Airport Limousine" />
                <meta data-react-helmet="true" property="og:type" content="business.local" />
                <meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Fleet" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
                <meta data-react-helmet="true" property="og:description" content="Discover the ultimate in luxury with Boston Airport Limousine's state-of-the-art fleet. Offering premium limousine services, airport transfers, and black car services tailored to your needs." />
            </Helmet>

            <PageHeader pageTitle="Boston Airport Limousine Fleet" />
            <div className='mx-16'>
                <section>
                    <p>
                        At <CompanyName />, we pride ourselves on offering state-of-the-art vehicles that redefine luxury, safety, and comfort. Whether you're seeking reliable limousine services, a seamless airport limousine service, or premium black car services, our fleet is designed to exceed expectations. Each vehicle is meticulously maintained to ensure the highest standards, making us the trusted choice for Boston airport limousine service and beyond. As a leading limo company, <CompanyName /> guarantees a sophisticated and stress-free travel experience, tailored to meet your every need.
                    </p>
                    <div className='mt-4 mb-8'><Divider /></div>
                </section>
                {Object.entries(
                    FleetData.reduce((acc, item) => {
                        acc[item.Class] = acc[item.Class] || [];
                        acc[item.Class].push(item);
                        return acc;
                    }, {})
                ).map(([className, vehicles]) => (
                    <section key={className} className="mb-8">

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                            {vehicles.map((item) => (
                                <div key={item.id} className="">
                                    <div className='py-4'>
                                        <span className="text-2xl font-bold">{item.Make} </span><span className="text-xl">{item.Model} | {className}</span>
                                    </div>
                                    <div className='my-2'><img src={item.Image} alt={`${item.Make} ${item.Model} ${className} Limousine service`} title={`${item.Make} ${item.Model} ${className} Limousine service`} /></div>
                                </div>
                            ))}
                        </div>
                    </section>
                ))}
            </div>
        </div>
    )
}

export default Fleet