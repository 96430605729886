import React from 'react';
import Button from '@mui/material/Button';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HeroImage from '../Images/pexels-guenude-nahum-692556131-28720713.jpg'

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#EA2B1F", // custom secondary color
        },
    },
});

const Hero = () => {
    return (
        <div className="relative h-screen w-full">
            {/* Background Image */}
            <img
                src={HeroImage}
                alt="Luxury Limousine Service"
                className="absolute inset-0 w-full h-full object-cover"
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            {/* Content */}
            <div className="relative flex flex-col items-center justify-center h-[62%] mx-auto text-center max-w-4xl px-4">
                <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight text-[#efa00b]">
                    Ride in Luxury. Arrive in Style.
                </h1>
                <div className="lg:text-lg md:text-sm lg:my-20 mb-8 text-[#efa00b]">
                    Boston's Premier Limousine Service for Business and Personal Travel.
                </div>
                <div className='lg:w-1/2'>
                    <ThemeProvider theme={theme}>
                        <Button
                            fullWidth
                            variant="contained"
                            id="btnReserveNow"
                            color="secondary"
                            style={{ color: "inherit" }}
                            size="large"
                            title="Reserve Your Limousine Now"
                            startIcon={<EventAvailableIcon />}
                        >
                            <b>Make a Reservation</b>
                        </Button>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default Hero