import React from 'react'
import PageHeader from '../Component/PageHeader';
import CompanyName from '../Component/CompanyName';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

const NightontheTownLimousine = () => {
	return (
		<div>
			<Helmet>
				<title>Night on the Town Limousine</title>
				<meta data-react-helmet="true" name="keywords" content="" />
				<meta data-react-helmet="true" name="description" content="" />
				<meta data-react-helmet="true" property="og:title" content="" />
				<meta data-react-helmet="true" property="og:type" content="business.local" />
				<meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Services/NightontheTownLimousine" />
				<meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
				<meta data-react-helmet="true" property="og:description" content="" />
			</Helmet>
			<PageHeader pageTitle="Night on the Town Limousine" />

			<div className='mx-16'>
				<section className='lg:float-right lg:ml-8 mb-8'>
					<figure>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/NightontheTown.webp?alt=media&token=9ba1415c-78f1-4792-95d0-c65df23bcfaf"
							alt="Airport Transfers"
							className='rounded-br-[45px] rounded-tl-[45px] w-full'
						/>
						<figcaption className="py-2 text-center">Luxury Transportation for Your Night Out</figcaption>
					</figure>
				</section>

				{/* Introduction Section */}
				<section>
					<p className='text-lg'>
						Transform your evening into a special event with our <CompanyName /> luxury limousine service. Perfect for celebrations, nights out, or memorable occasions, our chauffeurs provide stylish, safe, and comfortable transportation wherever you go.
					</p>
				</section>

				{/* Main Content Section */}
				<article className="mt-8">
					{/* Why Choose Us Section */}
					<section className="service-description">
						<div className='text-2xl py-4 font-bold'><h2>Why Choose Our Night on the Town Service?</h2></div>
						<p className='text-lg leading-relaxed'>
							Our night on the town service is designed to make your evening unforgettable. Here’s why you should choose us:
						</p>

						<ul className='py-4 list-disc list-inside'>
							<div className='mt-4 mb-8'><Divider /></div>
							<li className='py-2'>Luxury Fleet: Choose from limousines, party buses, or premium sedans to match the vibe of your evening.</li>
							<li className='py-2'>Safe and Reliable: Enjoy your night without worrying about driving or parking. We’ll take care of everything.</li>
							<li className='py-2'>Professional Chauffeurs: Courteous and experienced drivers who prioritize your safety and comfort.</li>
							<li className='py-2'>Memorable Experience: Make your evening unforgettable with our premium service and attention to detail.</li>
							<li className='py-2'>Customizable Options: Add special touches like champagne, decorations, or music to enhance your experience.</li>
						</ul>
					</section>

					{/* Ideal For Section */}
					<section className="service-ideal-for">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Ideal For</h2></div>
						<p className='text-lg leading-relaxed'>
							Our night on the town service is perfect for a wide range of celebrations and occasions. Here’s who can benefit the most:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'>Birthday celebrations, anniversaries, or romantic date nights.</li>
							<li className='py-2'>Group outings to restaurants, clubs, or theaters.</li>
							<li className='py-2'>Special occasions like proms, graduations, or milestone celebrations.</li>
							<li className='py-2'>Corporate events or team celebrations in style.</li>
						</ul>
					</section>

					{/* Fleet Options Section */}
					<section className="fleet-options">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Our Fleet Options</h2></div>
						<p className='text-lg leading-relaxed'>
							We offer a variety of vehicles to suit your needs and preferences. Whether you're planning an intimate evening or a group celebration, we have the perfect ride for you:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'>Luxury Sedans: Sleek and stylish, ideal for couples or small groups.</li>
							<li className='py-2'>Limousines: Make a grand entrance with our classic limousines, perfect for special occasions.</li>

						</ul>
					</section>

					{/* Booking Process Section */}
					<section className="booking-process">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>How to Book Your Night on the Town Service</h2></div>
						<p className='text-lg leading-relaxed'>
							Booking your luxury ride for a night out is quick and easy. Follow these simple steps:
						</p>
						<ol className='py-4 list-decimal list-inside'>
							<li className='py-2'>Choose Your Service: Select the type of vehicle and any additional amenities you’d like.</li>
							<li className='py-2'>Provide Your Details: Enter your pickup location, destination, and event details.</li>
							<li className='py-2'>Confirm Your Booking: Review your details and confirm your reservation.</li>
							<li className='py-2'>Enjoy Your Night: Sit back, relax, and let us handle the rest while you enjoy your evening.</li>
						</ol>
					</section>

					{/* Call to Action Section */}
					<section className="service-booking mt-8">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Book Your Night Out Today</h2></div>
						<p className='text-lg leading-relaxed'>
							Make your evening extraordinary with our luxury transportation service. Contact us to reserve your ride and ensure a memorable and stress-free night out. Whether it’s a celebration or a night of fun, we’re here to make it unforgettable.
						</p>
					</section>
				</article>
			</div>
		</div>
	)
}

export default NightontheTownLimousine