import React from 'react'
import PageHeader from '../Component/PageHeader';
import CompanyName from '../Component/CompanyName';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

const CorporateLimousine = () => {
	return (
		<div>
			<Helmet>
				<title>Conventions and Conferences - Corporate Limousine</title>
				<meta data-react-helmet="true" name="keywords" content="" />
				<meta data-react-helmet="true" name="description" content="" />
				<meta data-react-helmet="true" property="og:title" content="" />
				<meta data-react-helmet="true" property="og:type" content="business.local" />
				<meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Services/CorporateLimousine" />
				<meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
				<meta data-react-helmet="true" property="og:description" content="" />
			</Helmet>
			<PageHeader pageTitle="Conventions and Conferences - Corporate Limousine" />

			<div className='mx-16'>

				<section className='lg:float-right lg:ml-8 mb-8'>
					<figure>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/ConventionsandConferences.webp?alt=media&token=9075ab30-1737-4305-817e-6daee569d5fe"
							alt="Airport Transfers"
							className='rounded-br-[45px] rounded-tl-[45px] w-full'
						/>
						<figcaption className="py-2 text-center">Professional Transportation for Corporate Events</figcaption>
					</figure>
				</section>

				<section>
					<p className='text-lg'>
						Streamline your corporate travel with our <CompanyName /> executive livery service, designed to provide professional transportation for conventions and conferences. Whether you’re traveling solo or with a group, we ensure punctuality, comfort, and efficiency at every step of your journey.
					</p>
				</section>

				{/* Main Content Section */}
				<article className="mt-8">
					{/* Why Choose Us Section */}
					<section className="service-description">
						<div className='text-2xl py-4 font-bold'><h2>Why Choose Our Convention and Conference Service?</h2></div>
						<p className='text-lg leading-relaxed'>
							Our convention and conference transportation service is designed to meet the unique needs of corporate travelers. Here’s why you should choose us:
						</p>

						<ul className='py-4 list-disc list-inside'>
							<div className='mt-4 mb-8'><Divider /></div>
							<li className='py-2'><strong>Group Transportation:</strong> We offer vehicles that accommodate small to large groups, ensuring everyone travels together comfortably.</li>
							<li className='py-2'><strong>Timely Arrivals:</strong> Our chauffeurs monitor event schedules and traffic to ensure you arrive on time for every meeting or session.</li>
							<li className='py-2'><strong>Professionalism:</strong> Impress clients or colleagues with our polished and reliable service.</li>
							<li className='py-2'><strong>Custom Solutions:</strong> Tailored transportation plans to meet your event schedule and specific requirements.</li>
							<li className='py-2'><strong>Luxury Fleet:</strong> Choose from a range of luxury vehicles, including sedans, SUVs, and executive vans, all equipped with modern amenities.</li>
						</ul>
					</section>

					{/* Ideal For Section */}
					<section className="service-ideal-for">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Ideal For</h2></div>
						<p className='text-lg leading-relaxed'>
							Our convention and conference transportation service is perfect for a wide range of corporate and professional needs. Here’s who can benefit the most:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'><strong>Corporate Events and Trade Shows:</strong> Ensure seamless transportation for your team and clients.</li>
							<li className='py-2'><strong>International Conferences and Seminars:</strong> Provide a professional first impression for international attendees.</li>
							<li className='py-2'><strong>Group Travel for Business Professionals:</strong> Travel together in comfort and style, avoiding the hassle of coordinating multiple vehicles.</li>
							<li className='py-2'><strong>Networking Events:</strong> Make a lasting impression with our luxury transportation service.</li>
						</ul>
					</section>

					{/* Fleet Options Section */}
					<section className="fleet-options">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Our Fleet Options</h2></div>
						<p className='text-lg leading-relaxed'>
							We offer a variety of vehicles to suit your needs and preferences. Whether you're traveling solo or with a group, we have the perfect ride for you:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'><strong>Black Sedans:</strong> Sleek and professional, ideal for business travelers or small groups.</li>
							<li className='py-2'><strong>Luxury Limousines:</strong> Spacious and comfortable, perfect for mid-sized groups or executives.</li>

						</ul>
					</section>

					{/* Booking Process Section */}
					<section className="booking-process">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>How to Book Your Convention or Conference Transportation</h2></div>
						<p className='text-lg leading-relaxed'>
							Booking your transportation for conventions and conferences is quick and easy. Follow these simple steps:
						</p>
						<ol className='py-4 list-decimal list-inside'>
							<li className='py-2'><strong>Choose Your Service:</strong> Select the type of vehicle and service that best suits your needs.</li>
							<li className='py-2'><strong>Provide Your Details:</strong> Enter your event schedule, pickup location, and any special requests.</li>
							<li className='py-2'><strong>Confirm Your Booking:</strong> Review your details and confirm your reservation.</li>
							<li className='py-2'><strong>Enjoy Your Ride:</strong> Sit back, relax, and let us handle the rest.</li>
						</ol>
					</section>

					{/* Call to Action Section */}
					<section className="service-booking mt-8">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Book Your Corporate Transportation Today</h2></div>
						<p className='text-lg leading-relaxed'>
							Elevate your event experience with our professional and reliable transportation service. Contact us to arrange your convention or conference transportation and ensure a seamless journey for you and your team.
						</p>
					</section>
				</article>

			</div>
		</div>
	)
}

export default CorporateLimousine