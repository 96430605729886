import React from 'react';
import CompanyName from './Component/CompanyName';
import { Divider, Link, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Helmet } from 'react-helmet-async';
import PageHeader from './Component/PageHeader';



const today = new Date();
const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "long",   // Full weekday name (e.g., "Wednesday")
    day: "numeric",    // Day of the month (e.g., "4")
    month: "long",     // Full month name (e.g., "December")
    year: "numeric",   // Full year (e.g., "2024")
}).format(today);

const PrivacyPolicy = () => {
    return (
        <div>

            <Helmet>
                {/* Primary Meta Tags */}
                <title>Privacy Policy - Boston Logan Coach | Limousine Services</title>
                <meta name="description" content="Learn about the Privacy Policy of Boston Logan Coach, a premier limousine company. We prioritize your privacy, ensuring your data is secure while providing luxury transportation services." />
                <meta name="author" content="Boston Logan Coach" />
                <meta name="keywords" content="Boston Logan Coach Privacy Policy, Limousine Services, Boston Airport Limo, Data Security, Information Privacy, Luxury Transportation" />
                <meta name="robots" content="index, follow" />

                {/* Open Graph Meta Tags (for social media sharing) */}
                <meta property="og:title" content="Privacy Policy - Boston Logan Coach | Limousine Services" />
                <meta property="og:description" content="Explore Boston Logan Coach's Privacy Policy. As a trusted limousine company, we ensure the protection of your personal data while delivering premium airport and luxury transportation." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.bostonairportlimos.com/PrivacyPolicy" />
                <meta property="og:image" content="https://www.bostonairportlimos.com/OG.jpg" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://www.bostonairportlimos.com/PrivacyPolicy" />
            </Helmet>

            <div className="container mx-auto px-6 ">

                <PageHeader pageTitle={<><span>Privacy Policy for </span><CompanyName /></>} />


                <div><em>{formattedDate}</em></div>
                <div className='pt-4'>
                    <CompanyName /> is deeply committed to protecting your privacy and ensuring the confidentiality and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website, products, and services. By accessing or using our services, you consent to the practices described in this policy. If you do not agree with any part of this policy, please discontinue using our services.
                </div>
                <div className='py-8'><Divider /></div>
                <div className="text-xl font-bold pb-4">
                    1. Information We Collect
                </div>
                <div>
                    At <CompanyName />, we prioritize simplicity and transparency. As such, we collect the minimum information necessary to provide our services effectively. The types of information we may collect include:
                </div>
                <div className="text-xl font-bold py-4">a. Personal Information</div>
                <div>This refers to information that identifies you as an individual, such as:</div>
                <div>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Your name, email address, phone number, and other contact details when voluntarily provided by you through forms, inquiries, or other direct communication." />
                            </ListItemButton>
                        </ListItem>

                    </List>
                </div>
                <div className="text-xl font-bold pb-4">b. Non-Personal Information</div>
                <div>This includes data that cannot identify you personally, such as:</div>
                <div>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Browser type and version." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Operating system." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Referring and exit pages." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Date and time of your visit." />
                            </ListItemButton>
                        </ListItem>

                    </List>
                </div>

                <div className='py-4'>
                    <span className='font-bold'>Note:</span> At no point do we collect sensitive personal data unless explicitly required for the service you requested, and we will always obtain your consent for such collection.
                </div>

                <div className="text-xl font-bold pb-4">
                    2. How We Use Your Information
                </div>
                <div>We only use your information for legitimate purposes, such as:</div>

                <div>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Responding to your inquiries and providing customer support." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Improving our website and services based on user feedback." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Ensuring compliance with legal obligations and protecting our rights." />
                            </ListItemButton>
                        </ListItem>

                    </List>
                </div>

                <div>We do <span className='font-bold'>not</span> use your information for unsolicited marketing or advertising purposes.</div>

                <div className="text-xl font-bold py-4">
                    3. Information Sharing
                </div>

                <div>At <CompanyName />, we do not share, sell, rent, or disclose your personal information to third parties under any circumstances, except:</div>

                <div>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="1. When required by law, regulation, or legal process, such as in response to a subpoena." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="2. To enforce our terms of service or protect the rights, property, or safety of Boston Logan Coach, our users, or others" />
                            </ListItemButton>
                        </ListItem>



                    </List>
                </div>
                <div>
                    We take your privacy seriously and go to great lengths to ensure that your data remains confidential.
                </div>


                <div className="text-xl font-bold py-4">
                    4. Cookies and Tracking Technologies
                </div>

                <div>
                    To enhance your experience on our website, we may use cookies or similar tracking technologies. These technologies help us:
                </div>

                <div>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Understand user preferences." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Compile aggregate data about site traffic and interactions to optimize performance." />
                            </ListItemButton>
                        </ListItem>



                    </List>
                </div>
                <div>
                    You have the option to disable cookies through your browser settings. Please note that some features of our website may not function properly if cookies are disabled.
                </div>


                <div className="text-xl font-bold py-4">
                    5. Data Security
                </div>

                <div>
                    We implement robust security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. These measures include:
                </div>

                <div>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Encryption of sensitive data during transmission." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Regular security audits and software updates." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Restricted access to personal information, limited to authorized personnel only." />
                            </ListItemButton>
                        </ListItem>

                    </List>
                </div>

                <div>
                    While we strive to protect your data, no system can guarantee 100% security. You use our services at your own risk, and we encourage you to take precautions to safeguard your own information.
                </div>

                <div className="text-xl font-bold py-4">
                    6. Your Rights
                </div>

                <div>
                    Depending on your jurisdiction, you may have the following rights concerning your personal data:
                </div>

                <div>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="The right to access and obtain a copy of your data." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="The right to request corrections to any inaccuracies." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="The right to request the deletion of your data (subject to legal retention requirements)." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="The right to restrict or object to certain data processing activities." />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="The right to lodge a complaint with a relevant data protection authority." />
                            </ListItemButton>
                        </ListItem>


                    </List>
                </div>

                <div>
                    To exercise any of these rights, please <Link href='/ContactUs'>contact us</Link>.
                </div>

                <div className="text-xl font-bold py-4">
                    7. Retention of Information
                </div>

                <div>
                    We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Once the information is no longer needed, we securely delete or anonymize it.
                </div>

                <div className="text-xl font-bold py-4">
                    8. Third-Party Links
                </div>

                <div>
                    Our website may include links to third-party websites for your convenience. Please note that this Privacy Policy applies only to <CompanyName />. We are not responsible for the privacy practices or content of third-party sites. We encourage you to review the privacy policies of those websites before providing any personal information.
                </div>

                <div className="text-xl font-bold py-4">
                    9. Children's Privacy
                </div>

                <div>
                    Our services are not directed at children under the age of 13. We do not knowingly collect or store information from children. If we become aware that we have inadvertently collected personal information from a child, we will take steps to delete it promptly.
                </div>

                <div className="text-xl font-bold py-4">
                    10. Changes to This Privacy Policy
                </div>

                <div>
                    We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, or legal requirements. We will notify you of significant changes through our website or by other appropriate means. Your continued use of our services after any updates constitutes your acceptance of the revised policy.
                </div>

                <div className="text-xl font-bold py-4">
                    11. How to Contact Us
                </div>

                <div>
                    If you have any questions, concerns, or feedback about this Privacy Policy, please contact us at:
                </div>

                <div className='p-4 rounded-lg'>
                    <div className='font-bold py-4'><CompanyName /></div>
                    <address>
                        400 W Cummings Park 1725/179, Woburn, Massachusetts 01801
                    </address>
                    <div className='text-red-600 my-4 py-1'>
                        {/* Email Section */}
                        <div className="flex space-x-3">
                            <EmailOutlinedIcon fontSize="medium" />
                            <span>Email: </span>
                            <a
                                href="mailto:res@bostonairportlimos.com"
                                title="Send E-mail to Boston Logan Coach"
                                className="transition-colors"
                            >
                                res@bostonairportlimos.com
                            </a>
                        </div>


                        {/* Phone Section */}
                        <div className="flex space-x-3 py-1">
                            <CallOutlinedIcon fontSize="medium" />
                            <span>Direct: </span>
                            <a
                                href="tel:16172060358"
                                title="Call Boston Logan Coach"
                                className="transition-colors"
                            >
                                +1 (617) 206-0358
                            </a>
                        </div>

                        {/* WhatsApp Section */}
                        <div className="flex space-x-3 py-1">
                            <WhatsAppIcon fontSize="medium" />
                            <span>WhatsApp: </span>
                            <a
                                href="https://wa.me/16172060358?text=Hello!%20I%20am%20interested%20in%20your%20services."
                                title="Call or text Boston Logan Coach on WhatsApp"
                                className="transition-colors"
                            >
                                +1 (617) 206-0358
                            </a>
                        </div>


                    </div>
                </div>

                <div className="text-xl font-bold pb-4">
                    12. Acknowledgment
                </div>
                <div>
                    By using our website and services, you acknowledge that you have read, understood, and agree to this Privacy Policy. Your trust is important to us, and we are committed to safeguarding your information.
                </div>
                <div className='pt-8'>
                    Thank you for choosing <CompanyName />.
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy