import React from 'react'
import PageHeader from '../Component/PageHeader';
import CompanyName from '../Component/CompanyName';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

const AllDayLimousine = () => {
	return (
		<div>
			<Helmet>
				<title>Limousine Airport Transfers</title>
				<meta data-react-helmet="true" name="keywords" content="" />
				<meta data-react-helmet="true" name="description" content="" />
				<meta data-react-helmet="true" property="og:title" content="" />
				<meta data-react-helmet="true" property="og:type" content="business.local" />
				<meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Services/AllDayLimousine" />
				<meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
				<meta data-react-helmet="true" property="og:description" content="" />
			</Helmet>
			<PageHeader pageTitle="Dedicated All-Day Service / Hourly Limousine" />


			<div className='mx-16'>

				<section className='lg:float-right lg:ml-8 mb-8'>
					<figure>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/DedicatedAll-DayService.webp?alt=media&token=f5ecbbab-7d52-45e1-beb0-70bf249d2886"
							alt="Airport Transfers"
							className='rounded-br-[45px] rounded-tl-[45px] w-full'
						/>
						<figcaption className="py-2 text-center">Dedicated All-Day Service / Hourly Limousine</figcaption>
					</figure>
				</section>

				<section>
					<p className='text-lg'>
						Enjoy the ultimate flexibility with our <CompanyName /> dedicated all-day or hourly limousine service. Whether you're running errands, attending meetings, or exploring the city, our dedicated service provides luxury and reliability tailored to your unique schedule.
					</p>
				</section>

				{/* Main Content Section */}
				<article className="mt-8">
					{/* Why Choose Us Section */}
					<section className="service-description">
						<div className='text-2xl py-4 font-bold'><h2>Why Choose Our Dedicated All-Day or Hourly Service?</h2></div>
						<p className='text-lg leading-relaxed'>
							Our dedicated service is designed to provide you with the utmost convenience and luxury. Here’s why you should choose us:
						</p>

						<ul className='py-4 list-disc list-inside'>
							<div className='mt-4 mb-8'><Divider /></div>
							<li className='py-2'><strong>Customizable Plans:</strong> Book by the hour or for the entire day, depending on your needs.</li>
							<li className='py-2'><strong>Professional Chauffeurs:</strong> Experienced drivers who know the best routes and provide exceptional service.</li>
							<li className='py-2'><strong>Luxury Vehicles:</strong> Choose from a variety of vehicles to suit your preferences.</li>
							<li className='py-2'><strong>Unmatched Convenience:</strong> Your chauffeur will be at your disposal, ready to take you wherever you need to go.</li>
						</ul>
					</section>

					{/* Ideal For Section */}
					<section className="service-ideal-for">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Ideal For</h2></div>
						<p className='text-lg leading-relaxed'>
							Our dedicated all-day or hourly service is perfect for a wide range of needs. Here’s who can benefit the most:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'><strong>Corporate Executives:</strong> Stay on schedule with back-to-back meetings and events.</li>
							<li className='py-2'><strong>Tourists:</strong> Explore Boston’s top attractions in style and comfort.</li>
							<li className='py-2'><strong>Special Events:</strong> Make weddings, proms, or anniversaries unforgettable with our luxury service.</li>
							<li className='py-2'><strong>City Exploration:</strong> Run errands or enjoy a day out without worrying about parking or traffic.</li>
						</ul>
					</section>

					{/* Fleet Options Section */}
					<section className="fleet-options">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Our Fleet Options</h2></div>
						<p className='text-lg leading-relaxed'>
							We offer a variety of vehicles to suit your needs and preferences. Whether you're traveling solo or with a group, we have the perfect ride for you:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'><strong>Black Sedans:</strong> Sleek and professional, ideal for business travelers or solo trips.</li>
							<li className='py-2'><strong>Luxury Limousines:</strong> Spacious and comfortable, perfect for families or small groups.</li>
						</ul>
					</section>

					{/* Booking Process Section */}
					<section className="booking-process">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>How to Book Your Dedicated Service</h2></div>
						<p className='text-lg leading-relaxed'>
							Booking your dedicated all-day or hourly service is quick and easy. Follow these simple steps:
						</p>
						<ol className='py-4 list-decimal list-inside'>
							<li className='py-2'><strong>Choose Your Service:</strong> Select the type of vehicle and service duration that best suits your needs.</li>
							<li className='py-2'><strong>Provide Your Details:</strong> Enter your pickup location, destination, and any special requests.</li>
							<li className='py-2'><strong>Confirm Your Booking:</strong> Review your details and confirm your reservation.</li>
							<li className='py-2'><strong>Enjoy Your Ride:</strong> Sit back, relax, and let us handle the rest.</li>
						</ol>
					</section>

					{/* Call to Action Section */}
					<section className="service-booking mt-8">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Book Your Dedicated Service Today</h2></div>
						<p className='text-lg leading-relaxed'>
							Make every moment count. Contact us to reserve your all-day or hourly service and enjoy the ultimate in convenience and luxury. Whether you're exploring the city or attending back-to-back meetings, we’re here to make your journey seamless.
						</p>
					</section>
				</article>

			</div>
		</div>
	)
}

export default AllDayLimousine