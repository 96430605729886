import React from 'react'

function Developed() {

    return (
        <span className='custom-font'>
            Web Design & Development by <a href="https://webdesignexpressions.net/" title='Web Design Expressions, Professional Web Design Agency | Boston, Massachusetts, USA' target="_blank" rel="noreferrer" >Web Design Expressions, Inc</a>
        </span>
    )
}

export default Developed