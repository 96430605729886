import React, { useState, useEffect } from 'react';
import Footer from './Component/Footer';
import { Routes, Route } from "react-router-dom";
import Home from './Home';
import Reservations from './Reservations';
import NotFoundPage from './NotFoundPage'
import Careers from './Careers';
import PrivacyPolicy from './PrivacyPolicy';
import ContactUs from './ContactUs';
import AirportTransfers from './AirportTransfers';
import LimousineRates from './LimousineRates';
import Services from './LuxuryLimousineServices';
import MassachusettsLimousine from './LimousineService/MassachusettsLimousine';
import NewHampshireLimousine from './LimousineService/NewHampshireLimousine';
import RhodeIslandLimousine from './LimousineService/RhodeIslandLimousine';
import ConnecticutLimousine from './LimousineService/ConnecticutLimousine';
import MaineLimousine from './LimousineService/MaineLimousine';
import VermontLimousine from './LimousineService/VermontLimousine';
import NewJerseyLimousine from './LimousineService/NewJerseyLimousine';
import NewYorkLimousine from './LimousineService/NewYorkLimousine';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import Fleet from './Fleet';
import AirportTransfer from './Services/AirportTransfers';
import PointtoPointLimousine from './Services/PointtoPointLimousine';
import AllDayLimousine from './Services/AllDayLimousine';
import CorporateLimousine from './Services/CorporateLimousine';
import NightontheTownLimousine from './Services/NightontheTownLimousine';
import SportingEventsLimousine from './Services/SportingEventsLimousine';
import CasinoTripsLimousine from './Services/CasinoTripsLimousine';
import NewYearLimousine from './Services/NewYearLimousine';
import Navigation from './Component/Navigation';
// Background: #FFFFFF
// Primary: #1B3E5D Nile Blue
// Secondary: #C13E39 Turkish Rose


function App() {

  // const [menuOpen, setMenuOpen] = useState(false);

  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Toggle dark mode class on body and store the mode in localStorage
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('darkMode', isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <div className="BostonLoganCoach">
      <div className='mx-auto'>

        <Navigation isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} isOpen={isOpen} setIsOpen={setIsOpen} />
        <HelmetProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/LuxuryLimousineServices" element={<Services />} />
            <Route path="/LimousineRates" element={<LimousineRates />} />
            <Route path="/Reservations" element={<Reservations />} />
            <Route path="/Fleet" element={<Fleet />} />

            <Route path="/LimousineService/MassachusettsLimousine" element={<MassachusettsLimousine />} />
            <Route path="/LimousineService/NewHampshireLimousine" element={<NewHampshireLimousine />} />
            <Route path="/LimousineService/RhodeIslandLimousine" element={<RhodeIslandLimousine />} />
            <Route path="/LimousineService/ConnecticutLimousine" element={<ConnecticutLimousine />} />
            <Route path="/LimousineService/MaineLimousine" element={<MaineLimousine />} />
            <Route path="/LimousineService/VermontLimousine" element={<VermontLimousine />} />
            <Route path="/LimousineService/NewJerseyLimousine" element={<NewJerseyLimousine />} />
            <Route path="/LimousineService/NewYorkLimousine" element={<NewYorkLimousine />} />

            <Route path="/AirportTransfers" element={<AirportTransfers />} />
            <Route path="/Services/AirportTransfers" element={<AirportTransfer />} />
            <Route path="/Services/PointtoPointLimousine" element={<PointtoPointLimousine />} />
            <Route path="/Services/AllDayLimousine" element={<AllDayLimousine />} />
            <Route path="/Services/CorporateLimousine" element={<CorporateLimousine />} />
            <Route path="/Services/NightontheTownLimousine" element={<NightontheTownLimousine />} />
            <Route path="/Services/SportingEventsLimousine" element={<SportingEventsLimousine />} />
            <Route path="/Services/CasinoTripsLimousine" element={<CasinoTripsLimousine />} />
            <Route path="/Services/NewYearLimousine" element={<NewYearLimousine />} />

            <Route path="/Careers" element={<Careers />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/ContactUs" element={<ContactUs />} />

            {/* Catch-all route for 404 pages */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </HelmetProvider>

      </div>
      <div className='mx-16'>

        <div><Footer /></div>
      </div>
    </div >

  );
}

export default App;
