import React from 'react';
import Button from '@mui/material/Button';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#EA2B1F", // custom secondary color
        },
    },
});

const Action = () => {
    return (
        <div className='lg:flex lg:gap-8 mt-12 print:hidden'>
            <div className='lg:w-1/2'>
                <ThemeProvider theme={theme}>
                    <Button
                        fullWidth
                        variant="contained"
                        id="btnCallNow"
                        color="secondary"
                        style={{ color: "inherit" }}
                        size="large"
                        title="Call Boston Logan Coach Now"
                        startIcon={<LocalPhoneIcon />}
                    >
                        <b>Call Now</b>
                    </Button>
                </ThemeProvider>
            </div>

            <div className='lg:w-1/2 lg:my-0 my-4'>
                <ThemeProvider theme={theme}>
                    <Button
                        fullWidth
                        variant="contained"
                        id="btnReserveNow"
                        color="secondary"
                        style={{ color: "inherit" }}
                        size="large"
                        title="Reserve Your Limousine Now"
                        startIcon={<EventAvailableIcon />}
                    >
                        <b>Make a Reservation</b>
                    </Button>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default Action