import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React, { useState, useEffect } from "react";
import PageHeader from "./Component/PageHeader";
import { Helmet } from 'react-helmet-async';
import Divider from '@mui/material/Divider';

const ITEMS_PER_PAGE = 50;

const LimousineRates = () => {
    const [ratesData, setRatesData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortKey, setSortKey] = useState("City"); // Default sorting by city
    const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
    const [currentPage, setCurrentPage] = useState(1);

    // Fetch data from JSON file
    useEffect(() => {
        fetch("/Rates.json")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch the data.");
                }
                return response.json();
            })
            .then((data) => setRatesData(data))
            .catch((error) => console.error("Error loading JSON:", error));
    }, []);

    // Filter and sort data
    const filteredData = ratesData
        .filter(
            (item) =>
                item.Active === "YES" && // Only include active items
                (item.City.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.State && item.State.toLowerCase().includes(searchTerm.toLowerCase())))
        )
        .sort((a, b) => {
            // Group by State first
            const stateCompare = a.State.localeCompare(b.State);
            if (stateCompare !== 0) return stateCompare;

            // Then sort within the state based on sortKey
            const compareA = sortOrder === "asc" ? a[sortKey] : b[sortKey];
            const compareB = sortOrder === "asc" ? b[sortKey] : a[sortKey];
            return typeof compareA === "string"
                ? compareA.localeCompare(compareB)
                : compareA - compareB;
        });

    // Pagination logic
    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    // Handlers
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on new search
    };

    const handleSort = (key) => {
        if (sortKey === key) {
            // Toggle sort order
            setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortKey(key);
            setSortOrder("asc"); // Default to ascending when changing sort key
        }
    };

    const handlePageChange = (page) => setCurrentPage(page);

    return (
        <div >

            <Helmet>
                <title>Affordable Limousine Rates for Sedan and SUV</title>

                <meta data-react-helmet="true" name="description" content="Explore affordable limousine rates for sedan and SUV services, including luxury airport black car service and Boston airport limousine service." />
                <meta data-react-helmet="true" name="keywords" content="airport black car service, airport limo service Boston MA, black car services, Boston airport limo service, Boston airport limousine service, Boston limo service Logan Airport, limousine services, luxury limo service" />
                <meta data-react-helmet="true" property="og:title" content="Affordable Limousine Rates for Sedan and SUV" />
                <meta data-react-helmet="true" property="og:type" content="business.local" />
                <meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/LimousineRates" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
                <meta data-react-helmet="true" property="og:description" content="Discover affordable limousine rates for sedan and SUV services, offering luxury airport black car service and professional Boston airport limousine service to Logan Airport." />

            </Helmet>

            <PageHeader pageTitle="Affordable Limousine Rates for Sedan and SUV" />
            <div className="mx-16">

                {/* Search Bar */}
                <input
                    type="text"
                    placeholder="Search by city or state"
                    className="w-full p-2 border rounded mb-4"
                    onChange={handleSearch}
                />

                <article>
                    <section>
                        <p>
                            Travel in style and comfort with our exceptional limousine services, designed to make your journey to and from Logan Airport effortless. Whether you need a reliable ride for business meetings or a luxurious trip for a special occasion, our professional drivers and high-end vehicles ensure a seamless experience. Serving the Boston area, we provide timely and elegant transportation tailored to your schedule. Enjoy the convenience of a stress-free commute, combining sophistication with top-notch customer service. Let us take care of your travel needs, offering a premium solution for airport transfers and beyond.</p>
                    </section>
                </article>

                <div className="mt-4 mb-8"><Divider /></div>

                {/* Table */}
                <TableContainer>
                    <Table className="overflow-x-auto table-auto">
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => handleSort("City")} sx={{ color: "inherit" }}>
                                    <div className="font-bold text-lg">Cities / Towns {sortKey === "City" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                                </TableCell>
                                <TableCell onClick={() => handleSort("State")} sx={{ color: "inherit" }}>
                                    <div className="font-bold text-lg">State {sortKey === "State" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                                </TableCell>
                                <TableCell onClick={() => handleSort("Sedan")} sx={{ color: "inherit" }}>
                                    <div className="font-bold text-lg">Sedan Rate {sortKey === "Sedan" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                                </TableCell>
                                <TableCell onClick={() => handleSort("SUV")} sx={{ color: "inherit" }}>
                                    <div className="font-bold text-lg">SUV Rate {sortKey === "SUV" ? (sortOrder === "asc" ? "↑" : "↓") : ""}</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {paginatedData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ color: "inherit" }}>{item.City || "N/A"}</TableCell>
                                <TableCell sx={{ color: "inherit" }}>{item.State || "N/A"}</TableCell>
                                <TableCell sx={{ color: "inherit" }}>${Number(item.Sedan) || "-"}.00</TableCell>
                                <TableCell sx={{ color: "inherit" }}>${Number(item.SUV) || "-"}.00</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={4} sx={{ color: "inherit" }}>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <button
                                        key={i}
                                        className={`mx-1 px-3 py-1 rounded-lg border border-gray-300 ${currentPage === i + 1
                                            ? "bg-gray-100 text-black font-semibold"
                                            : "bg-white hover:bg-gray-100"
                                            }`}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </button>
                                ))}
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>


                {/* Pagination */}
                <div className="flex justify-center items-center mt-4">
                    <div className="inline-flex border-t border-gray-300 py-2">

                    </div>
                </div>
            </div>
        </div>
    );
};

export default LimousineRates;
