import React from 'react';
import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName';
import Divider from '@mui/material/Divider';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Services from './Services';
import airports from "./Component/Airports.json";
import { Helmet } from 'react-helmet-async';

const LuxuryLimousineServices = () => {
    return (
        <div>
            <Helmet>
                <title>Professional Limousine Services Customized to Your Needs</title>
                <meta data-react-helmet="true" name="keywords" content="airport black car service, airport limo service, airport limo service boston ma, airport limousine service, boston airport limo, boston airport limo service, boston airport limousine service, boston limo service logan airport" />
                <meta data-react-helmet="true" name="description" content="Experience premium luxury limousine services in Boston with our airport black car and limo services. Reliable, comfortable, and stylish transportation" />
                <meta data-react-helmet="true" property="og:title" content="Luxury Limousine Services, Boston Airport Limo" />
                <meta data-react-helmet="true" property="og:type" content="business.local" />
                <meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/LuxuryLimousineServices" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
                <meta data-react-helmet="true" property="og:description" content="Discover premium Boston airport limo and black car services. Reliable, luxurious, and tailored transportation for airport transfers, events, and more." />
            </Helmet>
            <PageHeader pageTitle="Professional Limousine Services Customized to Your Needs" />
            <div className='mx-16'>

                <div>
                    At <CompanyName />, we are committed to delivering exceptional transportation solutions that meet the unique needs of our valued clients. Whether you’re planning a night out, attending a business conference, or traveling between airports, we ensure a seamless, comfortable, and luxurious experience every time. Below, we highlight our specialized services, tailored to your lifestyle and events.
                </div>
                <div className='my-4'><Divider /></div>



                <div>
                    <Services />
                </div>


                <div className='lg:text-2xl font-bold py-8'>Airports We Serve</div>

                <div>
                    We proudly serve major airports across the region, ensuring smooth and punctual transportation for your travel needs:
                </div>

                <div>

                    {airports.map((airport, index) => (
                        <React.Fragment key={index}>
                            <TableContainer>
                                <Table style={{
                                    width: 'auto',
                                    marginLeft: 0,
                                }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'left', color: 'inherit', width: '10%' }}>
                                                <div>
                                                    <img
                                                        src={airport.logo}
                                                        alt={`${airport.name} logo`}
                                                        className="lg:w-[100px]"
                                                        title={`${airport.name} limo service`}

                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', textAlign: 'left', width: '90%', color: 'inherit' }}><div>{airport.name}, {airport.code}</div></TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>





                        </React.Fragment>
                    ))}

                </div >


                <div className='lg:text-2xl font-bold pt-8'>
                    Our Commitment to Excellence

                </div>

                <div className='py-4'>
                    <span className='font-bold '>Transparent Rates:</span> All rates include tolls, airport fees, and taxes. Please note that rates may vary on national holidays and during severe weather conditions.
                </div>

                <div >
                    <span className='font-bold'>Licensed and Insured Drivers:</span> All our chauffeurs and vehicles are fully licensed and insured, guaranteeing your safety and peace of mind.
                </div>



                <div className='lg:text-2xl font-bold pt-8'>
                    Special Occasions and Custom Requests
                </div>

                <div className='py-4'>
                    Let <CompanyName /> make your special occasions even more memorable. Whether it's a wedding, prom, birthday, or anniversary, we cater to your unique needs. Please provide advance notice so we can tailor our service to your requirements.
                </div>


                <div className='lg:text-2xl font-bold pt-8'>
                    Book Your Luxury Ride Today

                </div>

                <div>
                    Whether you're traveling for business or leisure, <CompanyName /> is your trusted partner for exceptional transportation services. Book your ride now and experience the ultimate in luxury, comfort, and professionalism.
                </div>
            </div >


        </div >

    )
}

export default LuxuryLimousineServices