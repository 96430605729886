import React from 'react'
import Developed from './Developed';
import CompanyName from './CompanyName';
import { Divider } from '@mui/material';
import Action from './Action';
import NavigationFooter from './NavigationFooter';

const today = new Date();

const Footer = () => {
    return (
        <div>
            <div className='print:hidden clear-both'><Action /></div>





            <div className='print:hidden'><NavigationFooter /></div>

            <div className='my-4'><Divider /></div>
            <div>&copy; {today.getFullYear()} - <span className='font-bold'><CompanyName /></span> Owned and Operated By BLC</div>

            <div className='print:hidden py-4'><Developed /></div>
        </div >
    )
}

export default Footer