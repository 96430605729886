import React from 'react';
import { Link } from '@mui/material';
import { ChevronRight } from 'lucide-react';

// Importing footer links data
import footerLinksData from './footer-links.json';

const NavigationFooter = () => {
    return (
        <footer className="py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
                    {Object.entries(footerLinksData).map(([columnTitle, links]) => (
                        <div key={columnTitle} className="space-y-4">
                            <h4 className="font-bold text-lg border-b border-gray-700 pb-2">
                                {columnTitle}
                            </h4>
                            <ul className="space-y-2">
                                {links.map((link, index) => (
                                    <li key={index}>
                                        <Link
                                            href={link.url}
                                            title={link.Linktitle}
                                            style={{ color: 'inherit' }}
                                            className="flex items-center text-gray-300 hover:text-white transition-colors duration-200 group"
                                        >
                                            <ChevronRight
                                                size={16}
                                                className="mr-2 text-gray-500 group-hover:text-white transition-colors duration-200"
                                            />
                                            {link.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>


            </div>
        </footer>
    );
};

export default NavigationFooter;