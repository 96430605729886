import React from 'react'
import PageHeader from '../Component/PageHeader';

import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

const PointtoPointLimousine = () => {
	return (
		<div>
			<Helmet>
				<title>Point-to-Point Limousine Service</title>
				<meta data-react-helmet="true" name="keywords" content="" />
				<meta data-react-helmet="true" name="description" content="" />
				<meta data-react-helmet="true" property="og:title" content="" />
				<meta data-react-helmet="true" property="og:type" content="business.local" />
				<meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Services/PointtoPointLimousine" />
				<meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
				<meta data-react-helmet="true" property="og:description" content="" />
			</Helmet>
			<PageHeader pageTitle="Point-to-Point Limousine Service" />

			<div className='mx-16'>
				<section className='lg:float-right lg:ml-8 mb-8'>
					<figure>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/Point-to-PointService.webp?alt=media&token=df8c7ada-de5d-492c-82c5-7f12ed69d711"
							alt="Airport Transfers"
							className='rounded-br-[45px] rounded-tl-[45px] w-full'
						/>
						<figcaption className="py-2 text-center">Point-to-Point Limousine Service</figcaption>
					</figure>
				</section>
				{/* Introduction Section */}
				<section>
					<p className='text-lg leading-relaxed'>
						Simplify your travel with our <strong>Boston limousine services</strong>, ideal for seamless trips between offices, hotels, or events. Whether you need a quick transfer or a longer ride, our point-to-point service ensures you arrive in style, comfort, and confidence.
					</p>
				</section>

				{/* Main Content Section */}
				<article className="service-content mt-8">
					{/* Why Choose Us Section */}
					<section className="service-description">
						<div className='text-2xl py-4 font-bold'><h2>Why Choose Our Point-to-Point Service?</h2></div>
						<p className='text-lg leading-relaxed'>
							Our <strong>point-to-point limousine service</strong> is designed to make your travel effortless and luxurious. Whether you're commuting between meetings, traveling to events, or exploring the city, we provide a seamless experience tailored to your needs.
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'><strong>Efficiency:</strong> Direct routes and professional drivers ensure you reach your destination on time.</li>
							<li className='py-2'><strong>Flexibility:</strong> Book a one-way trip or round-trip service tailored to your schedule.</li>
							<li className='py-2'><strong>Luxury Fleet:</strong> Travel in elegance with our range of vehicles, from sleek sedans to spacious SUVs.</li>
							<li className='py-2'><strong>Personalized Service:</strong> Our chauffeurs are trained to provide a smooth and enjoyable ride.</li>
							<li className='py-2'><strong>24/7 Availability:</strong> We’re here whenever you need us, day or night.</li>
						</ul>
					</section>

					{/* Ideal For Section */}
					<section className="service-ideal-for">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Ideal For</h2></div>
						<p className='text-lg leading-relaxed'>
							Our point-to-point service is perfect for a variety of travelers and occasions. Here’s who can benefit the most:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'><strong>Corporate Professionals:</strong> Travel between meetings or offices with ease and professionalism.</li>
							<li className='py-2'><strong>Travelers:</strong> Get to and from hotels, attractions, or events without the hassle of public transportation.</li>
							<li className='py-2'><strong>Special Occasions:</strong> Celebrate milestones like weddings, anniversaries, or proms with a luxurious ride.</li>
							<li className='py-2'><strong>Group Outings:</strong> Travel with friends or colleagues in comfort and style.</li>
						</ul>
					</section>

					{/* Fleet Options Section */}
					<section className="fleet-options">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Our Fleet Options</h2></div>
						<p className='text-lg leading-relaxed'>
							We offer a variety of vehicles to suit your needs and preferences. Whether you're traveling solo or with a group, we have the perfect ride for you:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'><strong>Black Sedans:</strong> Sleek and professional, ideal for business travelers or solo trips.</li>
							<li className='py-2'><strong>Luxury Limousines:</strong> Spacious and comfortable, perfect for families or small groups.</li>

						</ul>
					</section>

					{/* Booking Process Section */}
					<section className="booking-process">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>How to Book Your Point-to-Point Ride</h2></div>
						<p className='text-lg leading-relaxed'>
							Booking your point-to-point ride with us is quick and easy. Follow these simple steps:
						</p>
						<ol className='py-4 list-decimal list-inside'>
							<li className='py-2'><strong>Choose Your Service:</strong> Select the type of vehicle and service that best suits your needs.</li>
							<li className='py-2'><strong>Provide Your Details:</strong> Enter your pickup location, destination, and preferred time.</li>
							<li className='py-2'><strong>Confirm Your Booking:</strong> Review your details and confirm your reservation.</li>
							<li className='py-2'><strong>Enjoy Your Ride:</strong> Sit back, relax, and let us handle the rest.</li>
						</ol>
					</section>

					{/* Call to Action Section */}
					<section className="service-booking mt-8">
						<div className='text-2xl py-4 font-bold'><h2>Book Your Point-to-Point Ride Today</h2></div>
						<p className='text-lg leading-relaxed'>
							Experience the convenience of luxury travel. Contact us to schedule your next trip and enjoy a seamless, stress-free journey.
						</p>
						<button className="cta-button mt-4 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
							Contact Us
						</button>
					</section>
				</article>
			</div>
		</div>
	)
}

export default PointtoPointLimousine