import React from 'react'
import PageHeader from '../Component/PageHeader';
import CompanyName from '../Component/CompanyName';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

const NewYearLimousine = () => {
	return (
		<div>
			<Helmet>
				<title>New Year's Eve Celebrations Limousine</title>
				<meta data-react-helmet="true" name="keywords" content="" />
				<meta data-react-helmet="true" name="description" content="" />
				<meta data-react-helmet="true" property="og:title" content="" />
				<meta data-react-helmet="true" property="og:type" content="business.local" />
				<meta data-react-helmet="true" property="og:url" content="https://bostonairportlimos.com/Services/NewYearLimousine" />
				<meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/bostonairportlimos%2FBostonOG.jpg?alt=media&token=9283d758-d331-4727-9d32-db39652c82c6" />
				<meta data-react-helmet="true" property="og:description" content="" />
			</Helmet>
			<PageHeader pageTitle="New Year's Eve Celebrations Limousine" />

			<div className='mx-16'>
				<section className='lg:float-right lg:ml-8 mb-8'>
					<figure>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/NewYearsEveCelebrations.webp?alt=media&token=c462f125-0a05-405c-b4e8-0495a295831b"
							alt="Airport Transfers"
							className='rounded-br-[45px] rounded-tl-[45px] w-full'
						/>
						<figcaption className="py-2 text-center">Luxury Transportation for New Year's Eve</figcaption>
					</figure>
				</section>


				{/* Introduction Section */}
				<section>
					<p className='text-lg'>
						Celebrate New Year's Eve in style with our <CompanyName /> chauffeur-driven luxury service, offering stress-free rides for your festivities. Count on us for safe, comfortable, and timely transportation as you ring in the New Year. Let us handle the driving while you enjoy the celebration to the fullest.
					</p>
				</section>

				{/* Main Content Section */}
				<article className="mt-8">
					{/* Why Choose Us Section */}
					<section className="service-description">
						<div className='text-2xl py-4 font-bold'><h2>Why Choose Our New Year's Eve Service?</h2></div>
						<p className='text-lg leading-relaxed'>
							Our New Year's Eve transportation service is designed to make your celebration seamless and unforgettable. Here’s why you should choose us:
						</p>

						<ul className='py-4 list-disc list-inside'>
							<div className='mt-4 mb-8'><Divider /></div>
							<li className='py-2'>Luxury Fleet: Choose from limousines, party buses, or premium sedans to match the vibe of your celebration.</li>
							<li className='py-2'>Safe and Reliable: Enjoy your night without worrying about driving or navigating crowded streets.</li>
							<li className='py-2'>Professional Chauffeurs: Courteous and experienced drivers who prioritize your safety and comfort.</li>
							<li className='py-2'>Memorable Experience: Make your evening unforgettable with our premium service and attention to detail.</li>
							<li className='py-2'>Customizable Options: Add special touches like champagne, decorations, or music to enhance your celebration.</li>
						</ul>
					</section>

					{/* Ideal For Section */}
					<section className="service-ideal-for">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Ideal For</h2></div>
						<p className='text-lg leading-relaxed'>
							Our New Year's Eve service is perfect for a wide range of celebrations and occasions. Here’s who can benefit the most:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'>New Year's Eve parties or celebrations with friends and family.</li>
							<li className='py-2'>Group outings to restaurants, clubs, or events to ring in the New Year.</li>
							<li className='py-2'>Special occasions like weddings, anniversaries, or milestone celebrations.</li>
							<li className='py-2'>Corporate events or team celebrations to end the year in style.</li>
						</ul>
					</section>

					{/* Fleet Options Section */}
					<section className="fleet-options">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Our Fleet Options</h2></div>
						<p className='text-lg leading-relaxed'>
							We offer a variety of vehicles to suit your needs and preferences. Whether you're planning an intimate evening or a group celebration, we have the perfect ride for you:
						</p>
						<ul className='py-4 list-disc list-inside'>
							<li className='py-2'>Luxury Sedans: Sleek and stylish, ideal for couples or small groups.</li>
							<li className='py-2'>Limousines: Make a grand entrance with our classic limousines, perfect for special occasions.</li>

						</ul>
					</section>

					{/* Booking Process Section */}
					<section className="booking-process">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>How to Book Your New Year's Eve Ride</h2></div>
						<p className='text-lg leading-relaxed'>
							Booking your luxury ride for New Year's Eve is quick and easy. Follow these simple steps:
						</p>
						<ol className='py-4 list-decimal list-inside'>
							<li className='py-2'>Choose Your Service: Select the type of vehicle and any additional amenities you’d like.</li>
							<li className='py-2'>Provide Your Details: Enter your pickup location, destination, and event details.</li>
							<li className='py-2'>Confirm Your Booking: Review your details and confirm your reservation.</li>
							<li className='py-2'>Enjoy Your Night: Sit back, relax, and let us handle the rest while you celebrate.</li>
						</ol>
					</section>

					{/* Call to Action Section */}
					<section className="service-booking mt-8">
						<div className='mt-4 mb-4'><Divider /></div>
						<div className='text-2xl py-4 font-bold'><h2>Book Your New Year's Eve Ride Today</h2></div>
						<p className='text-lg leading-relaxed'>
							Ring in the New Year in style with our luxury transportation service. Contact us to reserve your ride and ensure a memorable and stress-free celebration. Whether it’s a party, dinner, or special event, we’re here to make your night unforgettable.
						</p>
					</section>
				</article>
			</div>
		</div>
	)
}

export default NewYearLimousine